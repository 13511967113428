@font-face {
	font-family: 'OpenSans-Light';
	src:
		local('Open Sans Light'),
		local('OpenSans-Light'),
		url('./OpenSans-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'OpenSans-Italic';
	src:
		local('Open Sans Italic'),
		local('OpenSans-Italic'),
		url('./OpenSans-Italic.woff') format('woff');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'OpenSans-Regular';
	src:
		local('Open Sans Regular'),
		local('OpenSans-Regular'),
		url('./OpenSans-Regular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'OpenSans-SemiBold';
	src:
		local('Open Sans SemiBold'),
		local('OpenSans-SemiBold'),
		url('./OpenSans-SemiBold.woff') format('woff');
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: 'OpenSans-Bold';
	src:
		local('Open Sans Bold'),
		local('OpenSans-Bold'),
		url('./OpenSans-Bold.woff') format('woff');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'OpenSans-BoldItalic';
	src:
		local('Open Sans Bold Italic'),
		local('OpenSans-BoldItalic'),
		url('./OpenSans-BoldItalic.woff') format('woff');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Conqueror';
	src:
		local('AWConqueror Small Caps'),
		url('./AWConquerorSmallCapsRegular.woff') format('woff');
	font-weight: normal;
	font-style: normal;
}

/* roboto-900 - latin */
@font-face {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 900;
	src:
		local(''),
		url('./roboto-v30-latin-900.woff2') format('woff2'),
		/* Super Modern Browsers */ url('./roboto-v30-latin-900.woff') format('woff'),
		/* Modern Browsers */ url('./roboto-v30-latin-900.ttf') format('truetype'),
		/* Safari, Android, iOS */ url('./roboto-v30-latin-900.svg#Roboto') format('svg'); /* Legacy iOS */
}

@font-face {
	font-family: 'Aladdin';
	font-style: normal;
	font-weight: normal;
	src: url('./Moss.ttf') format('truetype');
}

@font-face {
	font-family: 'Cinderella';
	font-style: normal;
	font-weight: normal;
	src: url('./Erin.ttf') format('truetype');
}

@font-face {
	font-family: 'Donald';
	font-style: normal;
	font-weight: normal;
	src: url('./Benny.ttf') format('truetype');
}

@font-face {
	font-family: 'Nemo';
	font-style: normal;
	font-weight: normal;
	src: url('./Erin.ttf') format('truetype');
}

@font-face {
	font-family: 'Scar';
	font-style: normal;
	font-weight: normal;
	src: url('./Erin.ttf') format('truetype');
}

@font-face {
	font-family: 'Tarzan';
	font-style: normal;
	font-weight: normal;
	src: url('./John.ttf') format('truetype');
}

@font-face {
	font-family: 'Woody';
	font-style: normal;
	font-weight: normal;
	src: url('./Benny.ttf') format('truetype');
}

@font-face {
	font-family: 'Lexend-Black';
	font-style: normal;
	font-weight: normal;
	src: url('./Lexend-Black.ttf') format('truetype');
}

@font-face {
	font-family: 'Lexend-Bold';
	font-style: normal;
	font-weight: normal;
	src: url('./Lexend-Bold.ttf') format('truetype');
}

@font-face {
	font-family: 'Lexend-ExtraBold';
	font-style: normal;
	font-weight: normal;
	src: url('./Lexend-ExtraBold.ttf') format('truetype');
}

@font-face {
	font-family: 'Lexend-ExtraLight';
	font-style: normal;
	font-weight: normal;
	src: url('./Lexend-ExtraLight.ttf') format('truetype');
}


@font-face {
	font-family: 'Lexend-Light';
	font-style: normal;
	font-weight: normal;
	src: url('./Lexend-Light.ttf') format('truetype');
}

@font-face {
	font-family: 'Lexend-Medium';
	font-style: normal;
	font-weight: normal;
	src: url('./Lexend-Medium.ttf') format('truetype');
}

@font-face {
	font-family: 'Lexend-Regular';
	font-style: normal;
	font-weight: normal;
	src: url('./Lexend-Regular.ttf') format('truetype');
}

@font-face {
	font-family: 'Lexend-SemiBold';
	font-style: normal;
	font-weight: normal;
	src: url('./Lexend-SemiBold.ttf') format('truetype');
}


@font-face {
	font-family: 'Lexend-Thin';
	font-style: normal;
	font-weight: normal;
	src: url('./Lexend-Thin.ttf') format('truetype');
}





